import { Model } from 'pinia-orm'

export default class AssessmentEntry extends Model {
  static entity = 'assessmentEntries'

  static fields () {
    return {
      createdAt: this.string(''),
      id: this.number(null),
      assessmentId: this.number(null),
      key: this.string(''),
      value: this.string(''),
      submittedAt: this.string(''),
    }
  }
}