import { createApp } from "vue"
import App from "./App.vue"
import { createWebHistory, createRouter } from "vue-router"
import routes from '~/routes.js'
import { createAuth0 } from '@auth0/auth0-vue'
import ElementPlus from "element-plus"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import all element css, uncommented next line
import "element-plus/dist/index.css"
import "~/assets/css/main.css"
import "~/assets/scss/index.scss"
import "~/styles/index.scss"
// import "~/styles/element/index.scss"
// If you want to use ElMessage, import it.
import "element-plus/theme-chalk/src/message.scss"
import api from '@/utilities/api'
import { createPinia } from 'pinia'
import { createORM } from 'pinia-orm'
import { setAuth0 } from '@/utilities/auth'
import modelUtils from '@/utilities/models'
import * as Sentry from '@sentry/vue';

const pinia = createPinia().use(createORM())

api.createInstance()

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    app,
    dsn: "https://a190c23ea3945c15e5086089a57c7d68@o4506356262240256.ingest.sentry.io/4506356264534016",
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/staging-api\.framewk\.com/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(ElementPlus)
app.use(router)
app.use(pinia)

const auth0 = createAuth0({
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  authorizationParams: {
    redirect_uri: window.location.origin + '/login-redirect',
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  },
  logoutParams: {
    returnTo: window.location.href,
  }
})

// Auth

app.use(auth0)
setAuth0(auth0)

const hasToken = api.setBearerToken()

if (hasToken) {
  modelUtils.user.fetchCurrentUser()
}

// Element icons

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mount("#app");
