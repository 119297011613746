import axios from 'axios'

let instance

export default {
    createInstance () {
        instance = axios.create({
            baseURL: import.meta.env.VITE_API_URL
        })

        return instance
    },

    setBearerToken () {
        // axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : null
        let token

        // Set the AUTH token for any request
        instance.interceptors.request.use((config) => {
            token = localStorage.getItem('auth_token')
            config.headers.Authorization = token ? `Bearer ${token}` : '';
            return config;
        })

        return !!token
    },

    removeBearerToken() {
        instance.interceptors.request.use(function (config) {
            config.headers.Authorization = ''
            return config;
        })
    },

    getAssessments(organisationId, userId) {
        return instance.get('/assessment', { organisationId, userId })
    },

    getAssessment(assessmentId, userId) {
        return instance.get(`/assessment/${assessmentId}`)
    },

    createAssessment(organisationGuid, formId, userId) {
        return instance.post('/assessment', { organisationGuid, formId, userId })
    },

    getAssessmentEntries(assessmentId, userId) {
        return instance.get(`/assessment/${assessmentId}/entries`, { userId })
    },

    postAssessmentEntries(assessmentId, formData, formSectionIndex, userId) {
        return instance.post(`/assessment/${assessmentId}/entries`, { formData, formSectionIndex, userId })
    },

    postAssessmentSubmission(assessmentId, formData, formSectionIndex, userId) {
        return instance.post(`/assessment/${assessmentId}/submit`, { formData, formSectionIndex, userId })
    },

    downloadPolicy(assessmentId, docType, userId) {
        return instance.get(`/assessment/${assessmentId}/download-policy/${docType}`, { responseType: 'blob' })
    },

    getAssessmentForm(version) {
        return instance.get(`/assessment/form-schema/${version}`)
    },

    getInvite(inviteId) {
        return instance.get(`/invite/${inviteId}`)
    },

    getUserByGuid(guid) {
        return instance.get(`/user/${guid}`)
    },

    getCurrentUser() {
        return instance.get(`/user/current`)
    },

    registerUser(payload) {
        return instance.post('/user/register', payload)
    }
}