// import Vue from 'vue'

const formatValue = (arg, value) => {
  if (arg === 'uppercase') {
    return value.toUpperCase()
  }

  if (arg === 'lowercase') {
    return value.toLowerCase()
  }

  if (arg === 'uppercaseFirst') {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  if (arg === 'uppercaseLettersNumbers') {
    // Uppercase
    // Remove anything except letters and numbers
    return value
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, '')
  }

  if (arg === 'uppercaseLettersNumbersSpaces') {
    // Uppercase
    // Remove anything except letters and numbers and spaces
    return value
      .toUpperCase()
      .replace(/[^a-zA-Z0-9 ]/g, '')
  }

  if (arg === 'lettersNumbersSpaces') {
    // Remove anything except letters and numbers and spaces
    return value
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, '')
  }

  if (arg === 'displayName') {
    // Replace spaces for _
    // Remove anything except letters and numbers and _
    return value
      .replace(' ', '_')
      .replace(/[^a-zA-Z0-9_]/g, '')
  }

  return value
}

// Vue.directive('formatter', {
export default {
  update: (_el, binding, vnode) => {
    if (!binding.arg) return

    const value = vnode.data.model.value

    if (!value) return

    const updatedValue = formatValue(binding.arg, value)

    if (updatedValue === value) return

    vnode.data.model.callback(updatedValue)
  },
}
