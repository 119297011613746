import { snakeCase } from 'lodash-es'
import slugify from '~/helpers/slugify'

export default (value, valueCase) => {
  let formattedValue

  switch (valueCase) {
    case 'capitalSnake':
      formattedValue = snakeCase(value).toUpperCase()
      break
    case 'snake':
      formattedValue = snakeCase(value)
      break
    case 'slugify':
      formattedValue = slugify(value)
      break
    default:
      formattedValue = value
      break
  }

  return formattedValue
}
