<script setup>
import VDynamicForm from '@/components/form/VDynamicForm.vue'
import api from '@/utilities/api'
import formSchema from '@/forms/register'
import formSchemaToModel from '@/helpers/form-schema-to-model'
import modelUtils from '@/utilities/models'
import { logError } from '@/utilities/logger'
import { login } from '@/utilities/auth'
import { ref, watch, computed, onMounted } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

// const { loginWithRedirect, isAuthenticated } = useAuth0()

const isSubmitting = ref(false)
const route = useRoute()
const router = useRouter()

// const formModel = computed(() => {
//   return formSchemaToModel({ schema: formSchema() })
// })

const invite = computed(() => {
  if (!route.query.inviteGuid) return
  return modelUtils.invites.getByGuid(route.query.inviteGuid)
})

const formModel = ref()

const formSchemaWithParams = computed(() => {
  return formSchema({ hasInvite: !!route.query.inviteGuid })
}) 

const updateModel = (initialData) => {
  const schemaModel = formSchemaToModel({ 
    schema: formSchemaWithParams.value,
  })

  formModel.value = {
    ...schemaModel,
    ...initialData,
  }
}

if (!route.query.inviteGuid) {
  updateModel()
}

const hasInviteFetchError = ref(false)

onMounted(async () => {
  if (route.query.inviteGuid && !invite.value) {
    try { 
      const invite = await modelUtils.invites.fetchByGuid(route.query.inviteGuid)
      updateModel({
        email: invite.email,
        organisationName: invite.organisation.name
      })
    } catch (error) {
      hasInviteFetchError.value = true
      logError(error)
    }
  }
})

// watch(invite, (newValue) => {
//   formModel.value.email = newValue.email
//   formModel.value.organisationName = newValue.organisation.name
// })

const isSuccessful = ref(false)

const handleSubmit = async (formData) => {
  isSubmitting.value = true

  const payload = {
    ...formData,
  }

  if (route.params.inviteGuid) {
    payload.inviteGuid = route.params.inviteGuid
  }

  try {
    await api.registerUser(payload)
    isSuccessful.value = true
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong when registering.')
  } finally {
    isSubmitting.value = false
  }
}

</script>

<template>
  <div class="l-container mt-8 mb-8">
    <div class="s-content">
      <h1 class="t-h2 t-hspaced" v-if="!isSuccessful">Register</h1>
      <h1 class="t-h2 t-hspaced" v-else>Registration successful</h1>
    </div>

    <div v-if="hasInviteFetchError">
      Error: Invalid invite code
    </div>

    <div v-if="isSuccessful">
      Now please log in:

      <el-button class="v-button v-button--primary v-button--large"
        @click="login(router)">
        Log in
      </el-button>
    </div>

    <template v-else>
      <div class="s-content">
        <p>
          Already registered?
          <a @click="login(router)">
            Log in
          </a>
        </p>
      </div>

      <VDynamicForm
        ref="dynamicForm"
        class="p-register__form v-dynamic-form--outlined v-dynamic-form--regular"
        :schema="formSchemaWithParams"
        :model="formModel"
        :is-submitting="isSubmitting"
        submit-text="Register"
        @submit="handleSubmit"
      />
    </template>
  </div>
</template>
