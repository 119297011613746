import formatString from './format-string'

export default (options, valueCase, addNoDetails = false) => {
  if (!options) return

  let returnArray = []

  if (Array.isArray(options)) {
    returnArray = options.map(value => ({
      label: value,
      value: formatString(value, valueCase),
    }))
  } else if (typeof options === 'object') {
    returnArray = Object.keys(options)
      .map(key => ({
        label: options[key],
        value: key,
      }))
  }

  if (addNoDetails) {
    returnArray.push({
      label: 'No details',
      value: null,
    })
  }

  return returnArray
}
