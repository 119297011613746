import * as Sentry from "@sentry/browser";

const captureError = (label, errorMessage, additionalData, $sentry) => {
    const message = errorMessage ? `${label}(): ${errorMessage}` : label
    const error = new Error(message)
    Sentry.captureException(error, additionalData)
    return error
}

const logError = (error) => {
    if (import.meta.env.VITE_LOG_ERRORS === 'true') {
        console.error(error.message)
    }

    Sentry.captureException(error)
}

const logErrorAndThrow = (error) => {
    logError(error)
    throw new Error (error)
}

export { logError, logErrorAndThrow, captureError }
