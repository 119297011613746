export default {
  GBP: '£ Pound sterling',
  EUR: '€ Euro',
  USD: '$ US dollar',
  JPY: 'Japanese yen',
  AUD: 'Australian dollar',
  CAD: 'Canadian dollar',
  CHF: 'Swiss franc',
  CNH: 'Chinese renminbi',
  HKD: 'Hong Kong dollar',
  NZD: 'New Zealand dollar'
}
