import Assessment from '@/store-models/assessment'
import AssessmentEntry from '@/store-models/assessment-entry'
import Invite from '@/store-models/invite'
import Organisation from '@/store-models/organisation'
import User from '@/store-models/user'
import api from './api'
import { useRepo } from 'pinia-orm'

// Helpers

const formatAssessment = assessment => {
    assessment.organisationGuid = assessment.organisation.guid
    return assessment
}

const formatAssessments = assessments => {
    return assessments?.map(formatAssessment)
}

const factory = (model) => ({    
    // getters
    getById: id => {
        return useRepo(model)
            .where('id', id)
            .first()
    },

    getByGuid: guid => {
        return useRepo(model)
            .where('guid', guid)
            .first()
    }
})

export default {
    assessments: {
        ...factory(Assessment),

        // fetchers

        async fetch(guid, userId) {
            const { data } = await api.getAssessments(guid, userId)
            const formattedAssessments = formatAssessments(data)
            return useRepo(Assessment).save(formattedAssessments)
        },

        async fetchOne(assessmentId, userId) {
            const { data } = await api.getAssessment(assessmentId, userId)
            const formattedAssessment = formatAssessment(data)
            return useRepo(Assessment).save(formattedAssessment)
        },

        async create(organisationGuid, userId) {
            const { data } = await api.createAssessment(organisationGuid, userId)
            return useRepo(Assessment).save(data)
        },

        // getters

        getByOrganisationGuid: (organisationGuid) => {
            return useRepo(Assessment)
                .where('organisationGuid', organisationGuid)
                .with('entries')
                .with('organisation')
                .get()
        }
    },
    assessmentEntries: {
        // fetchers

        async getEntries(assessmentId) {
            const { data } = await api.getAssessmentEntries(assessmentId)
            AssessmentEntry.save(data)
        },


        // getByFormId: formId => {
        //     return useRepo(Assessment)
        //         .where('formId', formId)
        //         .get()
        // }
    },
    invites: {
        ...factory(Invite),

        // fetchers
        async fetchByGuid(inviteGuid) {
            const { data } = await api.getInvite(inviteGuid)
            return useRepo(Invite).save(data[0])
        }
    },
    user: {
        ...factory(User),

        // getters

        getByGuid(guid) {
            return useRepo(User)
                .where('guid', guid)
                .with('organisation')
                .first()
        },

        // fetchers

        async fetchByGuid(userGuid) {
            const { data } = await api.getUserByGuid(userGuid)
            return useRepo(User).save(data)
        },

        async fetchCurrentUser() {
            const { data } = await api.getCurrentUser()
            const user = {
                ...data,
                isCurrent: true,
                organisationGuid: data.organisation.guid,
            }

            useRepo(Organisation).save(user.organisation)

            return useRepo(User).save(user)
        }
    }
}