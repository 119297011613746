<script setup>
// import formSchema from '@/forms/assessment-1'
import AssessmentSteps from '@/components/AssessmentSteps.vue'
import VDynamicForm from '@/components/form/VDynamicForm.vue'
import api from '@/utilities/api'
import country from '@/constants/models/country'
import createFormOptions from '@/helpers/create-form-options'
import currency from '@/constants/models/currency'
import { flow, pick, isEmpty } from 'lodash-es'
import formSchemaToModel from '@/helpers/form-schema-to-model'
import getObjectDiff from '@/helpers/get-object-diff'
import modelUtils from '@/utilities/models'
import { ElMessage } from 'element-plus'
import { logError } from '@/utilities/logger'
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const isFetching = ref(false)
const isSaving = ref(false)
const isSubmitting = ref(false)
const containerRef = ref()
const formModel = ref({})
const formModelBeforeChanges = ref({})
const formSchema = ref(null)
const dynamicFormRef = ref(null)

// @TODO change default
const userId = 1
const formSchemaId = 1

const assessment = computed(() => {
  return modelUtils.assessments.getByGuid(assessmentId.value)
})

const getFormModelChangeKeys = () => {
  return getObjectDiff(formModel.value, formModelBeforeChanges.value)
}

const handleSave = async (formData, isSubmitting = false) => {
  isSaving.value = true

  // const formDataArray = Object.entries(formData)
  
  // const filledFields = flow([
  //   Object.entries,
  //   array => array.filter(([key, value]) => !!value),
  //   Object.fromEntries
  // ])(formData)
    
  // if (!Object.keys(filledFields).length) {
  //   isSaving.value = false
  //   ElMessage.error('There were no fields to save')
  //   return
  // }

  const changedKeys = getFormModelChangeKeys()

  if (!changedKeys?.length) {
    if (!isSubmitting) {
      ElMessage.error('There were no fields to save')
    }
    isSaving.value = false
    return
  }

  const changedFields = pick(formData, changedKeys)
  // Check if we have any changes to make
  if (isEmpty(changedFields)) {
    isSaving.value = false
    return
  }

  try {
    await api.postAssessmentEntries(assessmentId.value, changedFields, sectionIndex.value, userId)
    ElMessage.success('Saved successfully')

    setFormModelBeforeChanges()
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong when submitting.')
  } finally {
    isSaving.value = false
  }
}

const handleBack = async () => {
  try {
    await router.push({
      params: {
        assessmentId: route.params.assessmentId,
        formSection: sectionIndex.value - 1
      }
    })

    containerRef.value.scrollIntoView({ behavior: 'smooth' })

  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong.')
  }
}

const handleSubmit = async (formData) => {
  isSubmitting.value = true

  try {
    await handleSave(formData, true)

    dynamicFormRef.value.resetValidation()

    if (sectionIndex.value === formSchema.value.sections.length) {
      if (!assessment.submittedAt) {
        await api.postAssessmentSubmission(assessmentId.value, formData, sectionIndex.value, userId)
      }

      await router.push({
        assessmentId: route.params.assessmentId,
        name: 'assessmentResults',
      })

      return
    }

    await router.push({
      params: {
        assessmentId: route.params.assessmentId,
        formSection: sectionIndex.value + 1
      }
    })

    containerRef.value.scrollIntoView({ behavior: 'smooth' })
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong when saving.')
  } finally {
    isSubmitting.value = false
  }
}

const currentSectionFields = computed(() => {
  if (!formSchema.value?.sections?.length) return []
  return formSchema.value.sections[sectionIndex.value - 1].fields
})

const currentSectionFieldsProps = computed(() => {
  return currentSectionFields.value.map(field => field.prop)
})

const entries = ref(null)

const fetchEntries = async () => {
  try {
    const entriesResponse = await api.getAssessmentEntries(assessmentId.value, userId)

    entries.value = Object.fromEntries(
      new Map(entriesResponse.data.map(entry => {
        const parsedValue = entry.value.slice(0,1) === '[' ? JSON.parse(entry.value) : entry.value
        return [entry.key, parsedValue]
      }))
    )
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong when fetching the entries.')
  }
}

const fetchFormSchema = async () => {
  try {
    const response = await api.getAssessmentForm(formSchemaId)
    const countryOptions = createFormOptions(country)
    const currencyOptions = createFormOptions(currency)

    let schema = response.data

    schema.sections = schema.sections.map((formSection) => {
      formSection.fields = formSection.fields.map(field => {
        if (field.options === 'country') {
          field.options = countryOptions
        }
        if (field.options === 'currency') {
          field.options = currencyOptions
        }
        return field
      })

      return formSection
    })

    formSchema.value = schema

    // setFormModel()
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, something went wrong when fetching the form.')
    throw error
  }
}

const assessmentId = computed(() => {
  return route.params.assessmentId
})

const sectionIndex = computed(() => {
  return Number(route.params.formSection)
})

const setFormModel = () => {
  formModel.value = formSchemaToModel({ 
    schema: formSchema.value,
    sectionIndex: sectionIndex.value,
    entries: entries.value
  })

  setFormModelBeforeChanges()
}

const setFormModelBeforeChanges = () => {
  formModelBeforeChanges.value = { ...formModel.value }
}

const isResultsSection = computed(() => {
  if (!formSchema.value) return false
  return sectionIndex.value === formSchema.value.sections.length + 1
})

const handleStepClick = (stepNo) => {
  // @TODO check if form is dirty

  if (stepNo >= assessment.value.currentSection) return

  router.push({
    params: {
      assessmentId: route.params.assessmentId,
      formSection: stepNo
    }
  })
}

onMounted(async () => {
  try {
    isFetching.value = true
    await fetchFormSchema()
    if (isResultsSection.value) return

    await modelUtils.assessments.fetchOne(route.params.assessmentId)
    await fetchEntries()
    setFormModel()
    isFetching.value = false
  } catch (error) {
    logError(error)
    ElMessage.error('Sorry, there was an error retrieving your assessments.')
  }
})

watch(sectionIndex, () => {
  setFormModel()
})

</script>

<template>
  <div class="bg-slate-500 mb-20 py-6 text-white">
    <div class="l-container">
      <h3 class="t-h3">Business risk assessment</h3>
    </div>
  </div>

  <div class="l-container" ref="containerRef">
    <AssessmentSteps 
      :sectionIndex="sectionIndex"
      @stepClick="handleStepClick" />

    <VDynamicForm
      ref="dynamicFormRef"
      class="p-register__form v-dynamic-form--regular v-dynamic-form--outlined"
      :allow-save="true"
      :fixed-footer="true"
      :is-saving="isSaving"
      :is-submitting="isSubmitting"
      :model="formModel"
      :schema="formSchema"
      :sectionIndex="sectionIndex"
      :submitText="sectionIndex === formSchema?.sections?.length ? 'Next' : 'Finish'"
      v-loading="isFetching"
      @back="handleBack"
      @save="handleSave"
      @submit="handleSubmit"
    />
  </div>
</template>
