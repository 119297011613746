import api from '@/utilities/api'
// import { useAuth0 } from '@auth0/auth0-vue';
// import { useRouter } from 'vue-router'

// const router = useRouter()

// const { loginWithRedirect, isAuthenticated } = useAuth0()

let auth0

export const setAuth0 = (auth0instance) => {
  auth0 = auth0instance
}

export const getAuth0 = () => {
  return auth0
}

export const login = async (router) => {
  // const payload = prompt ? { params: { prompt: 'login' } } : undefined
  // context.$auth.loginWith('auth0', payload)
  // loginWithRedirect()
  await auth0.loginWithPopup()

  // Logged in
  const { getAccessTokenSilently } = auth0
  const token = await getAccessTokenSilently()
  localStorage.setItem('auth_token', token)
  api.setBearerToken(token)

  // await modelUtils.user.fetchByGuid()

  await router.push({
    name: 'dashboard'
  })
}

export const logout = async () => {
  const { logout } = auth0
  await logout()
  localStorage.removeItem('auth_token')
  api.removeBearerToken()

  // if (!hard) {
  //   // Returns a promise
  //   return context.$auth.logout()
  // }

  // const logoutUrl = encodeURIComponent(process.env.siteUrl)

  // document.location.href = `https://watchdrop.eu.auth0.com/v2/logout?returnTo=${logoutUrl}`
}

export const isAdminUser = ($auth) => {
  if (!$auth?.loggedIn) return

  const roles = $auth.$storage?.getUniversal('roles')

  return !!roles?.length && !!roles.find(role => role.name === 'administrator')
}
