import { Model } from 'pinia-orm'
import Organisation from './organisation'

export default class Invite extends Model {
  static entity = 'invites'
  static primaryKey = 'guid'

  static fields () {
    return {
      createdAt: this.string(null),
      email: this.string(null),
      isUsed: this.boolean(false),
      guid: this.attr(null),
      organisation: this.belongsTo(Organisation, 'organisationId'),
      organisationId: this.attr(null),
      updatedAt: this.string(null),
      userId: this.string(null),
    }
  }
}