export default ({ schema, sectionIndex, entries, context, initialData = {} }) => {
  let model = initialData || {}

  // Handle empty schema

  if (!schema || (!schema.fields && !schema.sections)) {
    context?.$logger.capture('FormSchemaToModel', 'Form schema is empty', {
      extra: {
        schema,
      },
    })
    return model
  }

  const fieldFormatter = field => formatFields(field, entries)

  // If schema has no sections

  if (!schema.sections) {
    return Object.fromEntries(schema.fields.flatMap(fieldFormatter))
  }

  // Schema has sections

  if (sectionIndex) {
    return Object.fromEntries(schema.sections[sectionIndex - 1].fields.flatMap(fieldFormatter))
  }

  schema.sections.forEach((section) => {
    model = {
      ...model,
      ...initialData,
      ...Object.fromEntries(section.fields.flatMap(fieldFormatter)),
    }
  })

  return model
}

const formatField = (field, entries) => {
  let value

  if (entries) {
    value = entries[field.prop]
  } else {
    // If not matching entry found, set a default value
    value = field.type === 'checkboxes' ? []
      : field.type === 'switch' ? false
        : field.default || ''
  }

  return [field.prop, value]
}

const formatFields = (field, entries) => {
  const field1 = formatField(field, entries)

  if (!field.other) return [field1]

  // Other
  const field2 = formatField({
    ...field,
    prop: `${field.prop}Other`
  }, entries)

  return [field1, field2]
}
