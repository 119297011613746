<template>
  <div class="v-dynamic-form"
    :class="{ 'mb-[100px]': fixedFooter }">
    <el-form
      v-if="showFormElement"
      :id="schema.id"
      :ref="formRef"
      :autocomplete="schema.autocompleteDisabled ? 'off' : undefined"
      :label-position="labelPosition"
      :label-width="labelWidth"
      :model="model"
      :rules="rules"
      :status-icon="schema.statusIcon"
    >
      <slot name="header" />

      <div
        v-for="section in sections"
        :key="section.title || 'formSection'"
        class="v-dynamic-form__section"
      >
        <h3
          v-if="section.title"
          class="v-dynamic-form__section-title t-h2 t-hspaced"
        >
          {{ section.title }}
        </h3>

        <p
          v-if="section.description"
          class="v-dynamic-form__section-description"
        >
          {{ section.description }}
        </p>

        <div class="v-dynamic-form__section__fields">
          <el-form-item
            class="flex"
            v-for="field in visibleSectionFields"
            :key="`field_${field.prop}`"
            :class="field.formItemClass"
            :label-width="field.labelWidth"
            :prop="field.prop"
          >
            <div class="flex flex-col md:w-[40%] mb-2 md:mb-0">
              <label class="el-form-item__label">
                {{ renderLabel(field.label) }}
              </label>

              <el-popover
                v-if="field.tooltip"
                placement="top-start"
                trigger="hover"
                :content="field.tooltip"
                :width="350"
              >
                <template #reference>
                  <el-icon 
                    :size="24" 
                    class="mb-2 md:mt-5 md:mb-0 cursor-pointer" 
                    color="#64758B">
                    <QuestionFilled />
                  </el-icon>
                </template>
              </el-popover>
            </div>

            <div class="w-full md:w-[60%]">
              <p
                v-if="field.description"
                class="v-dynamic-form__field-description"
                v-html="field.description"
              />

              <p
                v-else-if="field.descriptionHtml"
                class="v-dynamic-form__field-description v-dynamic-form__field-description--html"
                v-html="field.descriptionHtml"
              />

              <el-input
                v-if="['text', 'password'].includes(field.type)"
                v-model="model[field.prop]"
                v-formatter:[field.directive]
                :autocomplete="field.autocompleteDisabled ? 'new-password' : undefined"
                :disabled="field.disabled"
                :show-password="field.type === 'password'"
                :minlength="field.min ? field.min : 0"
                :maxlength="field.max ? field.max : null"
                :show-word-limit="!!field.min || !!field.max"
                @blur="event => handleFieldBlur(field.prop, event.srcElement.value)"
              />

              <div v-else-if="field.type === 'number'">
                <VCurrency
                  v-if="field.showCurrencySymbolOutside"
                  key="bid-amount-currency"
                  class="p_currency-bid-amount"
                  :currency="field.currency"
                  :hide-amount="true"
                />

                <el-input-number
                  v-model.number="model[field.prop]"
                  class="v-dynamic-form__field--number"
                  :class="{
                    'v-dynamic-form__field--number--large': field.large,
                    'v-dynamic-form__field--number--is-dirty': field.isDirty,
                  }"
                  :disabled="field.disabled"
                  :min="field.min"
                  :max="field.max"
                  :step="field.step"
                  @change="value => handleFieldBlur(field.prop, value)"
                />
                {{ field.suffix }}
              </div>

              <div v-else-if="field.type === 'user'">
                <el-autocomplete
                  v-model="model[field.prop]"
                  class="v-dynamic-form__field--user"
                  :fetch-suggestions="handleUserSearch"
                  placeholder="Search"
                  @select="user => handleUserSelect(user, field.prop)"
                >
                  <template slot-scope="{ item }">
                    <div class="name">
                      {{ item.value }}
                    </div>
                  </template>
                </el-autocomplete>
              </div>

              <div
                v-else-if="field.type === 'price'"
                class="v-dynamic-form__field--price"
              >
                <div
                  v-if="field.currencyField"
                  class="v-dynamic-form__price-symbol"
                >
                  {{ currencySymbols[model[field.currencyField]] }}
                </div>
                <div
                  v-else-if="field.currency"
                  class="v-dynamic-form__price-symbol"
                >
                  {{ currencySymbols[field.currency] }}
                </div>

                <el-input
                  v-model.number="model[field.prop]"
                  type="number"
                  :disabled="field.disabled"
                  :min="field.min"
                  :max="field.max"
                  @blur="event => handleFieldBlur(field.prop, event.srcElement.value)"
                />
              </div>

              <el-input
                v-else-if="field.type === 'textarea'"
                v-model="model[field.prop]"
                :rows="field.rows || 3"
                type="textarea"
                @blur="event => handleFieldBlur(field.prop, event.srcElement.value)"
              />

              <el-switch
                v-else-if="field.type === 'switch'"
                v-model="model[field.prop]"
                :disabled="field.disabled"
                @change="value => handleFieldBlur(field.prop, value)"
              />

              <el-date-picker
                v-else-if="field.type === 'date'"
                v-model="model[field.prop]"
                placeholder="Pick a date"
                style="width: 100%;"
                :default-value="defaultDatetime"
                :type="field.datetime ? 'datetime' : 'date'"
                :format="field.format ? field.format : 'dd/MM/yyyy HH:mm:ss'"
                :value-format="field.valueFormat ? field.valueFormat : 'yyyy/MM/dd HH:mm:ss'"
                @change="value => handleFieldBlur(field.prop, value)"
              />

              <el-time-picker
                v-else-if="field.type === 'time'"
                v-model="model[field.prop]"
                placeholder="Pick a time"
                style="width: 100%;"
                @change="event => handleFieldBlur(field.prop, event.srcElement.value)"
              />

              <el-radio-group
                v-else-if="['radio', 'boolean'].includes(field.type)"
                :key="`field_radio_${field.label}`"
                v-model="model[field.prop]"
                @change="value => handleRadioChange(field, value)"
              >
                <el-radio
                  v-for="option in createRadioOptions(field)"
                  :border="true"
                  :key="option.value"
                  :label="option.value"
                >
                  {{ option.label }}
                </el-radio>
              </el-radio-group>

              <el-checkbox
                v-else-if="field.type === 'checkbox'"
                :key="`field_checkbox_${field.label}`"
                v-model="model[field.prop]"
                :label="field.value"
                :disabled="field.disabled ? field.disabled(model) || true : false"
              >
                {{ field.checkedLabel || 'True' }}
              </el-checkbox>

              <el-checkbox-group
                v-else-if="field.type === 'checkboxes' && field.options.length"
                v-model="model[field.prop]"
                @change="value => handleFieldBlur(field.prop, value)"
              >
                <el-checkbox
                  v-for="option in field.options"
                  :key="option.value || option.label"
                  :label="option.value || option.label"
                  :value="option.value || option.label"
                >
                  {{ option.label }}
                </el-checkbox>
              </el-checkbox-group>

              <el-select
                class="w-full md:w-auto md:min-w-[220px] lg:min-w-[280px]"
                v-else-if="['select', 'selectCountry'].includes(field.type)"
                v-model="model[field.prop]"
                :clearable="!!field.clearable"
                :disabled="field.disabled"
                :multiple="field.multiple"
                collapse-tags
                @change="value => handleSelectBlur(field, value)"
              >
                <template #header
                  v-if="field.multiple">
                  <el-checkbox
                    @change="checked => handleSelectAll(field.prop, createSelectOptions(field), checked)"
                  >
                    All
                  </el-checkbox>
                </template>
                <el-option
                  v-for="(option,index) in createSelectOptions(field)"
                  :key="option.label"
                  :label="option.label"
                  :value="field.indexOptionValues ? index : option.value || option.label"
                />
              </el-select>

              <el-alert 
                v-if="fieldFooter(field)"
                class="v-dynamic-form__field-footer"
                :title="fieldFooter(field).text" 
                :type="fieldFooter(field).isBlock ? 'error' : 'info'" 
                show-icon
                :closable="false" />
              
              <!-- Other -->
              <div v-if="field.other && model[field.prop] === 'OTHER'"
                class="flex">
                <p class="mr-4 mt-[3px]">Other:</p>

                <div v-if="['select', 'selectCountry'].includes(field.other.type)" class="w-full">
                  <el-select
                    class="w-full md:w-auto md:min-w-[220px] lg:min-w-[280px]"
                    v-model="model[`${field.prop}Other`]"
                    :clearable="!!field.other.clearable"
                    :disabled="field.other.disabled"
                    :multiple="field.other.multiple"
                    collapse-tags
                    @change="value => handleFieldBlur(field.other.prop, value)"
                  >
                    <template #header
                      v-if="field.other.multiple">
                      <el-checkbox
                        @change="checked => handleSelectAll(`${field.prop}Other`, createSelectOptions(field.other), checked)"
                      >
                        All
                      </el-checkbox>
                    </template>
                    <el-option
                      v-for="(option,index) in createSelectOptions(field.other)"
                      :key="option.label"
                      :label="option.label"
                      :value="field.other.indexOptionValues ? index : option.value || option.label"
                    />
                  </el-select>

                  <!-- <p class="mt-2"
                    v-if="field.other.type === 'selectCountry'">Shortcuts: <span 
                    class="t-link" 
                    @click="handleSelectAll(`${field.prop}Other`)">Select all</span> | <span 
                    class="t-link" 
                    @click="handlDeselectAll(`${field.prop}Other`)">Deselect all</span> </p> -->
                </div>

                <el-input
                  v-else
                  v-model="model[`${field.prop}Other`]"
                  v-formatter:[field.other.directive]
                  :disabled="field.other.disabled"
                  :minlength="field.other.min ? field.other.min : 0"
                  :maxlength="field.other.max ? field.other.max : null"
                  :show-word-limit="!!field.other.min || !!field.other.max"
                  @blur="event => handleFieldBlur(`${field.other.prop}Other`, event.srcElement.value)"
                />
              </div>
            </div>
          </el-form-item>
        </div>
      </div>

      <slot name="footer" />

      <div :class="{ 'fixed z-10 w-full bottom-0 left-0 bg-white p-4 md:p-8 border': fixedFooter }">
        <div 
          class="el-form-item--submit"
          :class="{ 'text-center': fixedFooter }">

          <el-button
            :id="`${schema.id ? `${schema.id}BackButton` : ''}`"
            class="v-button v-button--primary"
            :class="{
              'v-dynamic-form__submit-button--large': schema.largeSubmitButton,
              'v-button--large': schema.largeSubmitButton,
            }"
            type="primary"
            :disabled="hasDisabledButtons || sectionIndex === 1"
            :loading="isSubmitting"
            size="large"
            @click="handleBackClick"
          >
            <el-icon class="el-icon--right"><ArrowLeft /></el-icon> Back 
          </el-button>

          <el-button
            v-if="allowSave"
            :id="`${schema.id ? `${schema.id}SaveButton` : ''}`"
            class="v-button v-button--primary"
            :class="{
              'v-dynamic-form__submit-button--large': schema.largeSubmitButton,
              'v-button--large': schema.largeSubmitButton,
            }"
            type="primary"
            :disabled="hasDisabledButtons"
            :loading="isSaving"
            size="large"
            @click="handleSave(formRef)"
          >
            {{ saveText }}
          </el-button>

          <el-button
            :id="`${schema.id ? `${schema.id}SubmitButton` : ''}`"
            class="v-button v-button--primary"
            :class="{
              'v-dynamic-form__submit-button--large': schema.largeSubmitButton,
              'v-button--large': schema.largeSubmitButton,
            }"
            type="primary"
            :disabled="hasDisabledButtons"
            :loading="isSubmitting"
            size="large"
            @click="handleSubmit(formRef)"
          >
            {{ dynamicSubmitText }} <el-icon class="el-icon--right"><ArrowRight /></el-icon>
          </el-button>

          <el-alert
            v-if="showValidationMessage"
            class="v-dynamic-form__validation-message mt-8"
            title="There are validation issues above. Please correct them and try re-submitting."
            type="error"
            :closable="false"
          />
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import VCurrency from '~/components/global/VCurrency.vue'
import country from '@/constants/models/country'
import createFormOptions from '@/helpers/create-form-options'
import currencies from '~/constants/models/currency'
import currency from '@/constants/models/currency'
import currencySymbols from '~/constants/models/currency-symbols'
import format from 'date-fns/format'
import formatter from '~/directives/formatter.js'
import isSafari from '~/helpers/is-safari'
import { pick } from 'lodash-es'
import userSearch from '~/helpers/user-search'
// import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'

const otherOption = {
  label: 'Other',
  value: 'OTHER',
}

export default {
  name: 'VDynamicForm',

  components: {
    VCurrency,
    // ArrowLeft,
    // ArrowRight,
  },

  props: {
    formRef: {
      type: String,
      default: 'form',
    },

    schema: {
      type: Object,
      default: () => {},
    },

    model: {
      type: Object,
      default: () => {},
    },

    saveText: {
      type: String,
      default: 'Save',
    },

    submitText: {
      type: String,
      default: 'Submit',
    },

    nextSectionText: {
      type: String,
      default: 'Next',
    },

    labelWidth: {
      type: String,
      default: '100px',
    },

    resetFormOnSubmit: {
      type: Boolean,
      default: false,
    },

    allowSave: {
      type: Boolean,
      default: false,
    },

    isAdminUser: {
      type: Boolean,
      default: false,
    },

    isSaving: {
      type: Boolean,
      default: false,
    },

    isSubmitting: {
      type: Boolean,
      default: false,
    },

    labelPosition: {
      type: String,
      default: 'left',
    },

    scrollOnError: {
      type: Boolean,
      default: true,
    },

    isSaveDisabled: {
      type: Boolean,
      default: false,
    },

    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },

    sectionIndex: {
      type: Number,
    },
    
    fixedFooter: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    hasSubmitted: false,
    hasValidated: false,
    isValid: false,
    blockedFields: [],
  }),

  directives: {
    formatter
  },

  computed: {
    currencies () {
      return currencies
    },

    currencySymbols () {
      return currencySymbols
    },

    hasSections () {
      return !!this.schema.sections
    },

    isLastSection () {
      return this.hasSections && this.$route.params.formSection && this.schema.sections.length
    },

    dynamicSubmitText () {
      if (this.hasSections && this.isLastSection) return this.nextSectionText

      return this.submitText
    },

    hasDisabledButtons () {
      return this.isSaving || this.isSubmitting || this.isFormBlocked
    },

    fieldFooter () {
      return field => {
        if (field.type === 'radio' || field.type === 'select') {
          const fieldModel = this.model[field.prop]
          const isFieldSelected = !!fieldModel
          if (!isFieldSelected) return

          const selectedOption = field.options.find(option => option.value === fieldModel)
          if (!selectedOption) return
          const optionHasTooltip = selectedOption.blockTooltip || selectedOption.tooltip
          if (!optionHasTooltip) return
          
          return {
            text: selectedOption.blockTooltip || selectedOption.tooltip,
            isBlock: !!selectedOption.blockTooltip,
          }
        }

        return field.footer
      }
    },

    sections () {
      if (!this.schema) return []
    
      if (this.schema.sections) {
        if (this.sectionIndex === undefined) {
          return this.schema.sections
        }

        return [this.schema.sections[this.sectionIndex - 1]]
      }

      return [{
        title: '',
        fields: this.schema.fields,
      }]
    },

    visibleSectionFields () {
      return this.sections?.flatMap(section => section.fields?.filter((field) => {
        if (!('condition' in field)) return true
        if (typeof field.condition === 'function') return field.condition(this.model)
        return this.model[field.condition.fieldProp] === field.condition.value
      }))
    },

    visibleSectionFieldProps () {
      if (!this.hasSections) return []
      return this.visibleSectionFields?.map(field => field.prop) ?? []
    },

    showValidationMessage () {
      return !this.isSubmitting &&
        this.hasSubmitted &&
        this.hasValidated &&
        !this.isValid
    },

    rules () {
      if (!this.schema) return

      const fieldsWithRules = this.visibleSectionFields?.filter(field => field.rules)

      // Convert array of objects to keyed object
      // eslint-disable-next-line
      const inlineRules = fieldsWithRules?.reduce((obj, item) => (obj[item.prop] = item.rules, obj), {})

      return {
        ...this.schema.rules ?? {},
        ...inlineRules ?? {},
      }
    },

    defaultDatetime () {
      // For some reason, this breaks in Safari
      if (isSafari()) return null

      const date = format(Date.now(), "yyyy'-'MM'-'dd")
      return `${date} 19:30:00`
    },

    isFormBlocked () {
      return !!this.blockedFields.length
    },

    showFormElement () {
      return this.model && this.schema && !!Object.keys(this.model).length
    }
  },

  methods: {
    validateForm(formRef) {
      return this.$refs[formRef].validate((valid) => {
        this.hasValidated = true

        if (valid) {
          this.isValid = true
        } else {
          this.isValid = false

          if (this.scrollOnError) {
            this.$nextTick(() => {
              const firstError = this.$refs[this.formRef]?.$el.querySelector('.el-form-item.is-error')
              if (!firstError) return
              firstError.scrollIntoView({ behavior: 'smooth' })
            })
          }
        }
      })
    },

    async resetValidation () {
      // Clears validations which appear after progressing section
      await setTimeout(() => {
        this.$refs[this.formRef].clearValidate()
      }, 50)
    },

    handleBackClick () {
      this.$emit('back')
    },

    async handleSave (formRef, isSubmitting = false) {
      await this.validateForm(formRef)

      if (!isSubmitting) {
        this.$emit('save', this.model)
      }
    },

    async handleSubmit (formRef) {
      this.hasSubmitted = true

      // this.handleSave(formRef, true)

      await this.validateForm(formRef)

      if (!this.isValid) return

      // I don't think this is needed anymore? Just emit this.model. It contains the 'other' fields that this line misses out
      // let payload = !this.hasSections ? this.model : pick(this.model, this.visibleSectionFieldProps)

      this.$emit('submit', this.model)
    },

    handleSelectBlur (field, value) {
      this.handleFieldBlur(field.prop, value)

      if (field.type === 'selectCountry') return

      this.handleBlockedOption(field, value)
    },

    handleBlockedOption (field, value) {
      if (!field.options) return

      const isFieldBlocked = !!field.options.find(option => option.value === value)?.block
      if (!isFieldBlocked) {
        const foundIndex = this.blockedFields.findIndex(value => value === field.prop)
        if (foundIndex !== -1) {
          this.blockedFields.splice(foundIndex, 1)
        }
        return
      }

      this.blockedFields.push(field.prop)
    },

    handleFieldBlur (prop, value, _event) {
      this.$emit('blurInput', { prop, value })
    },

    handleRadioChange(field, value) {
      this.handleFieldBlur(field.prop, value)
      this.handleBlockedOption(field, value)

      if (field.other && value !== 'OTHER') {
        const otherProp = `${field.prop}Other`

        if (this.model[otherProp]) {
          // Clear the value of Other
          this.clearField(otherProp)
        }
      }
    },

    handleSelectAll(prop, options, checked) {
      // If unchecked, clear all selection
      if (!checked) {
        this.clearField(prop)
        return
      }

      this.model[prop] = options.map(option => option.value)
    },

    resetForm () {
      this.$refs[this.formRef].resetFields()
    },

    clearField (prop) {
      this.model[prop] = ''
    },

    renderLabel (label) {
      if (typeof label === 'function') return label(this.isAdminUser)
      return label
    },

    async handleUserSearch (queryString, callback) {
      if (!queryString) return
      const results = await userSearch(queryString)
      const resultsWithValue = results.map((user) => {
        user.value = `${user.firstName} ${user.lastName} (${user.displayName})`
        return user
      })
      callback(resultsWithValue)
    },

    handleUserSelect (user, fieldProp) {
      this.$set(this.model, `${fieldProp}Id`, user.id)
    },

    createSelectOptions (field) {
      if (field.options === 'country') return createFormOptions(country)
      if (field.options === 'currency') return createFormOptions(currency)

      let visibleOptions = [
        ...field.options,
      ]

      if (field.other) {
        visibleOptions.push(otherOption)
      }

      return visibleOptions
    },

    createRadioOptions (field) {
      if (field.type === 'boolean') {
        return [
          {
            label: 'Yes',
            value: 'Yes',
          },
          {
            label: 'No',
            value: 'No'
          },
        ]
      }

      let visibleOptions = [
        ...field.options.map(fieldOption => {
          if (!fieldOption.hasOwnProperty('value')) {
            fieldOption.value = fieldOption.label
          }

          return fieldOption
        })
      ]

      if (field.other) {
        visibleOptions.push(otherOption)
      }

      return visibleOptions
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vite-tools.scss";

.v-dynamic-form {
  &--outlined {
    padding: $spacer-lg;
    border: 2px solid $grey-7;

    @include breakpoint($bp-large) {
      padding: $spacer-xl;
    }
  }

  &--white {
    background-color: $white;
    padding: $spacer-lg;

    @include breakpoint($bp-large) {
      padding: $spacer-xl;
    }
  }

  &--centered {
    margin: 0 auto;
  }

  &--regular {
    max-width: 80rem;
  }

  &--narrow {
    max-width: 60rem;
  }

  &__field {
    &--price {
      position: relative;

      .el-input__inner {
        padding-left: 25px;
      }
    }

    &--user {
      width: 100%;

      @include breakpoint($bp-large) {
        width: 350px;
      }
    }

    &--number{
      &--large {
        .el-input {
          font-size: 2.1rem;
        }
      }

      .el-input__inner {
        color: $color-primary-text;
      }
    }

    &-footer {
      &.el-alert {
        line-height: 140%;
        margin-top: $spacer-md;
      }
    }
  }

  &__validation-message {
    margin-top: $spacer-xl;
    line-height: 140%;
  }

  &__field-description {
    color: var(--el-text-color-primary);
    line-height: $text-sm;
    margin-bottom: $spacer-md;
    line-height: 140%;

    a {
      color: $grey-4;
      text-decoration: underline;
    }
  }

  &__price-symbol {
    position: absolute;
    top: 0x;
    z-index: 10;
    left: 12px;
    font-size: 1.4rem;
    color: $grey-2;
    font-family: Arial, sans-serif;
  }

  &__section {
    margin-top: $spacer-xl;
    margin-bottom: $spacer-xl;

    &:first-of-type {
      margin-top: 0;
    }

    &-title {
      padding-bottom: $spacer-sm;
    }

    &-description {
      margin-bottom: $spacer-xl;
    }
  }

  &__footer {
    margin-bottom: $spacer-xl;
  }

  .el-radio {
    color: $color-primary-text;
  }

  .el-input__inner {
    border-radius: 0;
    color: $color-primary-text;

    &:focus {
      border-color: $grey-1;
    }
  }

  .el-input__wrapper {
    box-shadow: none;
    border: 1px solid $grey-6;
  }

  .el-form-item {
    margin-bottom: $spacer-xl;

    @include breakpoint($bp-small) {
      margin-bottom: $spacer-xxl;
    }

    &__label {
      font-size: $text-md;
      line-height: 135%;
      height: auto;
      text-align: left;
      font-weight: 500;
      padding: 0 0 $spacer-sm;

      @include breakpoint($bp-small) {
        padding: 0 50px $spacer-md 0;
      }
    }

    &--submit {
      margin-bottom: 0;

      .el-form-item__content {

      }
    }
  }

  .el-form-item__label {
    font-weight: bold;
  }

  .el-form--label-top {
    .el-form-item__label {
      height: auto;
      line-height: 1.6rem;
      margin-bottom: 1.2rem;
      padding: 0;
    }
  }

  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;

    .el-checkbox {
      width: 100%;
    }
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: $color-primary-text;
    font-weight: bold;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $grey-2;
    border-color: $grey-2;
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $grey-2;
    background-color: $grey-2;
  }

  .el-radio {
    white-space: normal;
    margin-bottom: 1rem;
    height: auto;
    line-height: 130%;

    &.is-bordered {
      border: 1px solid $grey-7;
      border-radius: 4px;
      padding: 1rem;

      &:hover {
        border-color: $grey-5;
      }

      &.is-checked {
        border-color: $grey-2;
      }
    }

    &__label {
      padding-left: 1.25rem;
    }
  }

  .el-form-item__content {
    margin-left: auto !important;
    align-items: start;
  }

  &__submit-button--large {
    width: 100%;
  }
}
</style>
