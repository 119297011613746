<script setup>
import { useAuth0 } from '@auth0/auth0-vue'
import { useRouter } from 'vue-router'
import { logError } from '@/utilities/logger'
import { logout } from '@/utilities/auth'

const router = useRouter()
const { user, isAuthenticated } = useAuth0()

const handleLogout = () => {
    logout()
}

const handleLogoClick = async () => {
    try {
      await router.push({
        name: 'home',
      })
      return
    } catch (error) {
      logError(error)
    }
}

const handleDashboard = async () => {
    try {
      await router.push({
        name: 'dashboard',
      })
      return
    } catch (error) {
      logError(error)
    }
}
</script>

<template>
    <div class="border-b flex py-4 md:py-8">
        <div class="l-container flex justify-between w-full">
            <img src="/framewk-logo.png" 
                class="w-[160px] my-auto h-auto cursor-pointer" 
                @click="handleLogoClick"/>

            <div v-if="isAuthenticated && user">
                <span class="siteHeaderUsername max-w-[160px] md:max-w-none">Logged in as {{ user.nickname }}</span>
                <span class="t-link"
                @click="handleDashboard">Dashboard</span><br/>
                <span class="t-link"
                @click="handleLogout">Logout</span>
            </div>
        </div>
    </div>
</template>

<style scoped>
.siteHeaderUsername {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;    
  white-space: nowrap;
  width: 100%;
}
</style>