<script setup>

const props = defineProps(['sectionIndex'])
const emit = defineEmits(['step-click'])

const handleStepClick = (stepNo) => {
  emit('step-click', stepNo)
}

const isMobile = () => {
  return window.innerWidth >= 768
}

</script>

<template>
    <el-steps 
        finish-status="success" 
        class="mb-12 md:mb-20"
        :direction="isMobile() ? 'horizontal' : 'vertical'"
        :active="sectionIndex - 1">
      <el-step title="General business information" 
        @click="handleStepClick(1)" />
      <el-step title="Customer risk" @click="handleStepClick(2)" />
      <el-step title="Product risk" @click="handleStepClick(3)" />
      <el-step title="Country risk" @click="handleStepClick(4)"  />
      <el-step title="Channel risk" @click="handleStepClick(5)" />
      <el-step title="Results" />
    </el-steps>
</template>