<template>
  <div
    class="v-currency"
    :class="{ 'v-currency--inline': inline }"
  >
    {{ calculatedCurrency }}
  </div>
</template>

<script>
import formatCurrency from '@/helpers/format-currency'
import currencySymbols from '@/constants/models/currency-symbols'

export default {
  props: {
    amount: {
      type: Number,
      default: 0,
      // required: true
    },
    currency: {
      type: String,
      required: true,
    },
    hideAmount: Boolean,
    hideCommas: Boolean,
    inline: Boolean,
  },

  data () {
    return {
      activeIndex: '1',
    }
  },

  computed: {
    calculatedCurrency () {
      if (this.hideAmount) { return currencySymbols[this.currency] }

      return formatCurrency(this.amount, this.currency, this.hideCommas)
    },
  },
}
</script>

<style lang="scss">
  .v-currency {
    &--inline {
      display: inline;
    }
  }
</style>
