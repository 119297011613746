import axios from 'axios'

export default async (queryString) => {
  const headers = {
    'Content-Type': 'application/json',
  }
  try {
    const result = await axios.get(`${process.env.netlifyFunctionsUrl}/user-search?q=${queryString}`, { headers })
    return result?.data
  } catch (error) {
    throw new Error(error.response?.data || error)
  }
}
