<script setup>
import { useAuth0 } from '@auth0/auth0-vue';
import { logError, logErrorAndThrow } from '@/utilities/logger'
import { useRouter } from 'vue-router'
import { ref, onMounted, computed } from 'vue'
import api from '@/utilities/api'
import modelUtils from '@/utilities/models'
import { login } from '@/utilities/auth.js'

const { isAuthenticated } = useAuth0()

const router = useRouter()

const handleLogin = async () => {
  login()
}

const isSubmitting = ref(false)

// @TODO change these defaults
const organisationId = 1
const userId = '1'

const handleStart = async () => {
  isSubmitting.value = true

  if (!isAuthenticated.value) {
    try {
      await router.push({
        name: 'register',
      })
      return
    } catch (error) {
      logError(error)
    }
  }

  try {
    await router.push({
      name: 'dashboard',
    })
  } catch (error) {
    logError(error)
  }

  return
}

// onMounted(async () => { 
//   try {
//     await modelUtils.assessments.fetch(organisationId, userId)
//     // await modelUtils.assessments.getEntries(organisationId, userId)
//   } catch (error) {
//     logErrorAndThrow(error)
//   }
// })

const assessments = computed(() => {
  if (!organisationId || !userId) return
  return modelUtils.assessments.getByOrganisationId(organisationId)
})

</script>

<template>
  <div>

    <!-- <p v-if="!isAuthenticated">
      Register
      <router-link to="/register">
        Register
      </router-link>

      <br/>
      Already registered?<br/>
      <button @click="handleLogin">
        Log in
      </button>
    </p> -->

      <div class="py-20 mb-20 bg-navy text-white">
        <div class="l-container">
          <div class="md:max-w-[60%]">
            <h1 class="t-h1 t-hspaced leading-tight">Get your business compliant and help reduce financial crime</h1>
            <p class="text-lg mb-6">
              Risk Assessments must be undertaken by businesses to identify, assess, and mitigate the risks associated with money laundering and terrorist financing activities. The purpose of this assessment is to ensure that businesses understand this risk and implement risk-based controls to detect and minimise the amount of financial crime they are exposed to.
            </p>
            <p class="text-lg">
              Once the assessment is complete you can update and change your answers at any time - for example if you introduce new products or offer your services to new types of customers.</p>
            <p class="text-lg">
              This should take around 20–30 minutes. It can be saved and resumed at any time.</p>

            <el-button
                class="v-button v-button--blue v-button--xlarge mt-12"
                type="primary"
                :loading="isSubmitting"
                size="large"
                @click="handleStart"
              >
              Start now
            </el-button>
          </div>
        </div>
      </div>

      <div class="l-container mb-20">
        <h2 class="t-h2 t-hspaced">Frequently asked questions</h2>

        <el-collapse>
            <el-collapse-item title="Where is my data stored?" name="1">
              <div>
              Your data is stored securely on our servers in London, UK.
              </div>
            </el-collapse-item>
            <el-collapse-item title="What happens if I don’t have all the answers to these questions?" name="2">
              <div>
              You don’t need to complete all the questions in one session. Your responses are automatically saved for you to come back to. Completing these questions allows you to assess the risk of your business and helps you understand your legal obligations.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Why do I need to complete all the questions?" name="3">
              <div>
              The Regulations that apply to your business stipulate that certain information about your business must be taken into account when assessing the risk of your business. We’ve translated these into easy to answer questions for you so you don’t have to repeat the same information over and over again.
              </div>
            </el-collapse-item>
        </el-collapse>
      </div>
  </div>
</template>
