import Home from '@/pages/index.vue'
import Register from '@/pages/register.vue'
import Assessment from '@/pages/assessment.vue'
import AssessmentResults from '@/pages/assessment-results.vue'
import Dashboard from '@/pages/dashboard.vue'
import { authGuard } from '@auth0/auth0-vue'
import modelUtils from '@/utilities/models'

export default [
    {   path: '/', 
        component: Home,
        name: 'home',
    },
    {   path: '/invite', 
        name: 'invite',
        beforeEnter: async (to, from, next) => {
            if (!to.query.inviteCode) {
                next({ name: 'home'})
                return
            }

            try {
                // Check it's a valid invite code
                const invite = await modelUtils.invites.fetchByGuid(to.query.inviteCode)
                next({ 
                    name: 'register',
                    query: {
                        inviteGuid: invite.guid,
                        // email: invite[0].email,
                        // organisationGuid: invite[0].organisation.guid,
                    }
                })
                return
            } catch (error) {
                console.log(error)
                next({ 
                    name: 'home',
                    query: {
                        error: 'INVITE_INVALID'
                    }
                })
            }
        },
    },
    { 
        path: '/register', 
        component: Register,
        name: 'register'
    },
    { 
        path: '/assessment/:assessmentId/section/:formSection', 
        component: Assessment,
        name: 'assessment',
        beforeEnter: authGuard,
    },
    { 
        path: '/assessment/:assessmentId/results', 
        component: AssessmentResults,
        name: 'assessmentResults',
        beforeEnter: authGuard,
    },
    { 
        path: '/dashboard', 
        component: Dashboard,
        name: 'dashboard',
        beforeEnter: authGuard,
    },
    // { 
    //     path: '/assessment/:assessmentId/results', 
    //     component: AssessmentResults,
    //     name: 'assessmentResults',
    //     beforeEnter: authGuard,
    // },
]
