<script setup>
    import AssessmentSteps from '@/components/AssessmentSteps.vue'
    import api from '@/utilities/api'
    import content from '@/content/en.json'
    import format from 'date-fns/format'
    import modelUtils from '@/utilities/models'
    import { ElMessage } from 'element-plus'
    import { logError, logErrorAndThrow } from '@/utilities/logger'
    import { ref, onMounted, computed } from 'vue'
    import { useRouter, useRoute } from 'vue-router'

    const isFetching = ref(false)
    const route = useRoute()
    const router = useRouter()
    const userId = '1'
    const DATE_FORMAT = 'd MMM yyyy HH:mm'
    const isDownloading = ref(false)

    const riskScoreSections = [
        { 
            name: 'Customer risk',
            key: 'customer',
        },
        { 
            name: 'Product risk',
            key: 'product',
        },
        { 
            name: 'Country risk',
            key: 'country',
        },
        { 
            name: 'Channel risk',
            key: 'channel',
        },
    ]

    const customColorMethod = (percentage) => {
        if (percentage < 33.333) {
            return '#67c23a'
        }
        if (percentage < 66.666) {
            return '#e6a23c'
        }
        if (percentage < 100) {
            return '#e66e24'
        }
        return '#f56c6c'
        }

    const scoreObjects = [
        { 
            label: 'Very high', 
            percentage: 100,
            key: 'veryHigh',
        },
        { 
            label: 'High', 
            percentage: 66.666,
            key: 'high',
        },
        { 
            label: 'Medium', 
            percentage: 33.333,
            key: 'medium',
        },
        { 
            label: 'Low',
            percentage: 1,
            key: 'low',
        },
        // { label: 'Very poor', percentage: 80 },
        // { label: 'Poor', percentage: 60 },
        // { label: 'Average', percentage: 40 },
        // { label: 'Good', percentage: 20 },
        // { label: 'Very good', percentage: 10 },
        // { label: 'Excellent', percentage: 0 },
    ]
    
    const scoreLabel = computed(() => {
        if (!assessment.value?.latestRiskScore) return ''
        const score = Number(assessment.value.latestRiskScore.value) // 1-4
        return getRiskScoreObject(score)?.label
    })

    const getRiskScoreObject = score => {
        const MODIFIER = 33.333 // 1-4 > 0-100 = 33.333
        const scorePercentage = (score - 1) * MODIFIER + 1
        return scoreObjects.find(label => label.percentage <= scorePercentage)
    }

    onMounted(async () => {
        try {
            isFetching.value = true
            await modelUtils.assessments.fetchOne(route.params.assessmentId, userId)
        } catch (error) {
            logError(error)
            ElMessage.error('Sorry, there was an error retrieving this assessment.')
        } finally {
            isFetching.value = false
        }
    })

    const assessment = computed(() => {
        return modelUtils.assessments.getByGuid(route.params.assessmentId)
    })

    const policies = [
        {
            name: 'Anti-Money Laundering & Counter Terrorist Financing policy',
            slug: 'anti-money-laundering-ct'
        },
        {
            name: 'Anti-Bribery policy',
            slug: 'anti-bribery',
            comingSoon: true,
        },
        {
            name: 'Anti-Tax Evasion policy',
            slug: 'anti-tax-evasion policy',
            comingSoon: true,
        },
        {
            name: 'Sanctions policy',
            slug: 'sanctions',
            comingSoon: true,
        },
        {
            name: 'Fraud policy',
            slug: 'fraud',
            comingSoon: true,
        },
        // {
        //     name: 'Policy two',
        // },
        // {
        //     name: 'Policy three',
        // },
    ]

    const handleDownloadPolicy = async (assessmentId, docType) => {
        isDownloading.value = true
        ElMessage.info('Generating policy, please wait...')

        // Function that downloads PDF
        function download(data) {
            const url = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.download = `framewk-assessment-${assessmentId}-${docType}.pdf`;
            a.href = url;
            a.target = '_self';

            a.click();

            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            a.remove();
            URL.revokeObjectURL(url);
            }, 100);
        }

        try {
            const response = await api.downloadPolicy(assessmentId, docType, userId)
            download(response.data)
        } catch (error) {
            logError(error)
            ElMessage.error('Sorry, something went wrong generating the policy.')
        } finally {
            isDownloading.value = false
        }
    }

    const handleFeedbackClick = () => {
        window.open('https://forms.gle/ojf2hGQmoaeHrwDA7')
    }

    const handleEdit = () => {
        router.push({
            name: 'assessment',
            params: {
                assessmentId: route.params.assessmentId,
                formSection: 1,
            }
        })
    }

</script>

<template>
    <div class="relative pb-[80px]">
        <div class="bg-slate-500 py-6 text-white">
            <div class="l-container">
            <h3 class="t-h3">Business risk assessment</h3>
            </div>
        </div>

        <div>
            <div v-if="isFetching" v-loading="true"
            class="w-full h-20" />

            <div class="mb-20 py-16 bg-slate-300 w-full" 
                v-if="assessment">
                <div class="l-container">
                    <div class="mb-12">
                        <h1 class="t-h1">Your assessment</h1>
                    </div>

                    <div class="flex flex-col md:flex-row gap-12 w-full">
                        <div class="bg-white rounded-xl p-8 w-full md:w-1/2 lg:w-3/5 flex flex-col">
                            <h2 class="t-h2">Your risk scores</h2>

                            <el-alert 
                                title="There was an error retreiving your risk scores" 
                                type="error"
                                v-if="!assessment.latestRiskScores" />
                            <template v-else>
                                <p class="text-sm mb-12"
                                    v-if="assessment.latestRiskScores.sections?.channel?.createdAt">Calculated at: {{ format(new Date(assessment.latestRiskScores.sections.channel.createdAt), DATE_FORMAT) }}</p>
                                <div class="flex flex-wrap">
                                    <div class="my-auto flex flex-col w-full mb-12"
                                        :key="section.key"
                                        v-for="(section, index) in riskScoreSections">
                                        <p class="font-bold text-xl mb-8">{{ section.name }}</p>
                                        <div class="flex flex-col md:flex-row w-full pb-12 md:pb-8"
                                            :class="{
                                                'border-b': index !== policies.length - 1
                                            }">
                                            <el-progress 
                                                class="text-center flex justify-center md:mr-16 mb-4 md:mb-8"
                                                type="dashboard" 
                                                :percentage="getRiskScoreObject(assessment.latestRiskScores.sections[section.key]?.value)?.percentage" 
                                                :color="customColorMethod">
                                                <p class="font-bold text-xl">{{ getRiskScoreObject(assessment.latestRiskScores.sections[section.key]?.value)?.label }}</p>
                                            </el-progress>
                                            <div>
                                                <p class="font-bold">What this means for your business</p>
                                                {{ content[`assesmentResults.risks.${section.key}.${getRiskScoreObject(assessment.latestRiskScores.sections[section.key]?.value)?.key}`] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        
                        <div class="w-full md:w-1/2 lg:w-2/5 flex flex-col gap-12">
                            <div class="bg-white rounded-xl p-8">
                                <h2 class="t-h2 t-hspaced">Download policies</h2>
                                <ul>
                                    <li v-for="policy in policies"
                                        :key="policy.name"
                                        @click="handleDownloadPolicy(assessment.id, policy.slug)"
                                        class="border mb-4 p-4 flex align-center relative"
                                        :class="{
                                            'border-grey-200 hover:border-grey-400 cursor-pointer': !policy.comingSoon,
                                            'border-grey-100': policy.comingSoon,
                                        }">
                                        <el-icon 
                                            :size="24" 
                                            class="mr-6 no-inherit" 
                                            :color="policy.comingSoon ? '#BBB' : '#666'">
                                            <Loading v-if="!policy.comingSoon && isDownloading" />
                                            <Document v-else />
                                        </el-icon>
                                        <div>
                                            <p :class="{
                                                'font-bold': !policy.comingSoon,
                                                }">{{ policy.name }}</p>
                                            <p class="text-sm text-grey-600">PDF</p>
                                            <p class="text-sm text-grey-600 bg-grey-200 inline-block leading-none p-2 px-4 rounded-lg absolute top-4 right-4" 
                                                v-if="policy.comingSoon">COMING SOON</p>
                                        </div>
                                    </li>
                                    <li>
                                        <el-alert title="We're currently working on adding new policy downloads." type="info" show-icon :closable="false" />
                                    </li>
                                </ul>
                            </div>

                            <div class="bg-white rounded-xl p-8">
                                <h2 class="t-h2 t-hspaced">Actions</h2>
                                <p class="mb-8">If anything has changed within your business, you can update your policy updating the assessment questions.</p>

                                <button
                                    class="v-button v-button--secondary v-button--mobile-full rounded font-bold"
                                    @click="handleEdit">
                                    Update assessment questions
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="l-container">
                <h2 class="t-h2 t-hspaced">Frequently asked questions</h2>

                <el-collapse class="mb-20">
                    <el-collapse-item title="How is my risk score calculated?" name="1">
                        <div>
                        The risk score takes into account regulations and guidance on what is considered low to high risk in relation to financial crime and applies a numerical score to this, with the higher risks having a higher number and the lower risks having a lower number. This generates an initial risk score for your business if you implemented standard controls required by law.
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="What can I do to improve my risk score?" name="2">
                        <div>
                        This risk assessment is designed to help you identify the financial crime risks in your business based on your current set-up. In order to lower your risk you may need to re-consider aspects of your product design or the types of customers you plan on accepting if you are unable to implement sufficient controls to manage the risk. 
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="How does my risk score change over time?" name="4">
                        <div>
                        Your risk score might change later on when you carry out another risk assessment and aspects of your business have changed or you change your products or services. In addition, regulations and guidance may change - some types of risk, for example customer risk might be identified as higher or lower than it was before due to changes in your industry.
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="What happens next?" name="5">
                        <div>
                        Carrying out a risk assessment and creating company policies is a good start to creating a compliance programme! However you need to make sure that you implement appropriate controls so that your policies are followed in practice, as well as ensure that they are working correctly. The ability to do this in Framewk is coming soon!
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <div class="w-full bg-grey-900 fixed left-0 bottom-0 text-white">
            <div class="l-container p-4 md:p-6 flex flex-col md:flex-row md:justify-between">
                <div class="mb-4 md:mb-0">
                    <h3 class="t-h3">Help us improve our service</h3>
                    <p class="hidden md:block">We'd love to get your feedback on assessment process. Tell us what you think - it'll take just 2 minutes.</p>
                </div>
                <button
                    class="v-button v-button--large v-button--blue rounded font-bold"
                    @click="handleFeedbackClick">
                    Answer 8 questions
                </button>
            </div>
        </div>
    </div>
</template>