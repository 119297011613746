// import emailValidator from '@/forms/validators/email-address'

const termsAgreementValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error('You must agree to the terms to register'))
    return
  }
  callback()
}

export default ({ displayNameValidator, emailAutocompleteDisabled = false, hasInvite = false } = {}) => {
  return {
  autocompleteDisabled: emailAutocompleteDisabled,
  id: 'register',
  fields: [
    {
      label: 'First name',
      prop: 'firstName',
      type: 'text',
      directive: 'uppercaseFirst',
    },
    {
      label: 'Last name',
      prop: 'lastName',
      type: 'text',
      directive: 'uppercaseFirst',
    },
    {
      label: 'Organisation name',
      prop: 'organisationName',
      description: 'Please enter the full legal name',
      type: 'text',
      disabled: hasInvite,
    },
    {
      label: 'Email address',
      prop: 'email',
      type: 'text',
      directive: 'lowercase',
      autocompleteDisabled: emailAutocompleteDisabled,
      disabled: emailAutocompleteDisabled,
      disabled: hasInvite,
    },
    {
      label: 'Choose a password',
      prop: 'password',
      type: 'password',
      description: 'For your security, it must contain:<br/>- 8-20 characters<br/>- A lower and upper case letter<br/>- A number<br/>- A special character (!@#$%^&*)',
      min: 8,
      max: 20,
    },
    // {
    //   label: 'Sign-up to our weekly emails',
    //   prop: 'weeklyEmailsAgreement',
    //   type: 'switch',
    //   description: 'Get a weekly digest of our latest listed watches. You can unsubscribe at any time. We will never spam you or sell your details.',
    // },
    {
      label: 'Terms agreement',
      prop: 'termsAgreement',
      type: 'switch',
      description: 'I agree to the <a href="/terms-conditions" target="_blank">terms and conditions</a>.',
    },
  ],

  rules: {
    firstName: [
      { required: true, message: 'This field is required', trigger: 'blur' },
    ],
    lastName: [
      { required: true, message: 'This field is required', trigger: 'blur' },
    ],
    email: [
      { required: true, message: 'This field is required', trigger: 'blur' },
      { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] },
      // { validator: emailValidator, trigger: 'blur' },
    ],
    password: [
      { required: true, message: 'This field is required', trigger: 'blur' },
      { min: 8, max: 20, message: 'This field should be 8-20 characters', trigger: 'blur' },
      {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,100})/,
        message: 'This password does not meet the above rules',
        trigger: 'blur',
      },
    ],
    organisationName: [
      { required: true, message: 'This field is required', trigger: 'blur' },
    ],
    termsAgreement: [
      { validator: termsAgreementValidator, trigger: 'blur' },
    ],
  },
}}
