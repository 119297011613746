import { Model } from 'pinia-orm'
import Assessment from './assessment'

export default class Organisation extends Model {
  static entity = 'companies'
  static primaryKey = 'guid'

  static fields () {
    return {
      assessments: this.hasMany(Assessment, 'organisationGuid'),
      createdAt: this.string(''),
      email: this.string(''),
      guid: this.attr(null),
      id: this.number(null),
      name: this.string(''),
    }
  }
}