import { Model } from 'pinia-orm'
import AssessmentEntry from './assessment-entry'
import Organisation from './organisation'
import { orderBy } from 'lodash-es'

export default class Assessment extends Model {
  static entity = 'assessments'
  static primaryKey = 'guid'

  static fields () {
    return {
      createdAt: this.string(null),
      organisationGuid: this.string(null),
      organisation: this.belongsTo(Organisation, 'organisationGuid'),
      currentSection: this.number(1),
      entries: this.hasMany(AssessmentEntry, 'assessmentId'),
      guid: this.string(null),
      id: this.number(null),
      meta: this.attr([]),
      riskScore: this.number(null),
      schemaVersion: this.string(null),
      section: this.string(null),
      submittedAt: this.string(null),
      updatedAt: this.string(null),
      userId: this.string(null),
    }
  }

  get status () {
    return this.submittedAt ? 'COMPLETE' : 'INCOMPLETE'
  }

  get latestRiskScores () {
    if (!this.meta?.length) return

    const filterBySection = (sectionKey) => {
      const riskScoreMetas = this.meta.filter(meta => meta.key === `riskScore_${sectionKey}`)
      if(!riskScoreMetas) return 
      return orderBy(riskScoreMetas, 'createdAt', 'desc')[0]
    }

    return {
      // createdAt: 
      sections: {
        channel: filterBySection('channelRisk'),
        country: filterBySection('countryRisk'),
        customer: filterBySection('customerRisk'),
        product: filterBySection('productRisk'),
      }
    }
  }
}