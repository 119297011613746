import { Model } from 'pinia-orm'
import Organisation from './organisation.js'

export default class User extends Model {
  static entity = 'users'

  static fields () {
    return {
      auth0UserId: this.string(''),
      createdAt: this.string(''),
      organisationGuid: this.string(''),
      organisationId: this.string(''),
      organisation: this.belongsTo(Organisation, 'organisationGuid'),
      email: this.string(''),
      firstName: this.string(''),
      id: this.number(null),
      isCurrent: this.boolean(false),
    }
  }
}