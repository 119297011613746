<script setup>
import format from 'date-fns/format'
import modelUtils from '@/utilities/models'
import { ElMessage } from 'element-plus'
import { logError } from '@/utilities/logger'
import { logErrorAndThrow } from '@/utilities/logger'
import { ref, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'

const DATE_FORMAT = 'd MMM yyyy HH:mm'

const isFetching = ref(false)
const router = useRouter()

onMounted(async () => { 
  try {
    isFetching.value = true

    await modelUtils.user.fetchCurrentUser()
    await modelUtils.assessments.fetch(user.value.organisation.guid, user.value.id)
    // await modelUtils.assessments.getEntries(organisationGuid, userId)
  } catch (error) {
    ElMessage.error('Sorry, something went wrong when fetching the current user.')
    logError (error)
  } finally {
    isFetching.value = false
  }
})

const user = computed(() => {
  return modelUtils.user.getByGuid()
})

const assessments = computed(() => {
  if (!user.value?.organisation?.guid) return []
  return modelUtils.assessments.getByOrganisationGuid(user.value.organisation.guid)
})

const handleResume = async (assessmentGuid, status, currentSection) => {
  try {
    let path

    if (status === 'COMPLETE') {
      path = `/assessment/${assessmentGuid}/results`
    } else {
      path = `/assessment/${assessmentGuid}/section/${currentSection}`
    }
    
    await router.push({
      path, 
    })
  } catch (error) {
    logError(error)
  }
}

const handleStart = async () => {
  try {
    const assessment = await modelUtils.assessments.create(user.value.organisation.guid, user.id)

    await router.push({
      name: 'assessment',
      params: {
        assessmentId: assessment.guid,
        formSection: '1',
      }
    })
  } catch (error) {
    ElMessage.error('Sorry, something went wrong when creating the assessment.')
    logError(error)
  } finally {
    // isSubmitting.value = false
  }
}
</script>

<template>
  <div class="l-container my-12 md:my-20">
    <h1 class="t-h1 t-hspaced">Your assessments</h1>

    <div v-if="isFetching" 
      v-loading="isFetching"
      class="w-full h-20" />

    <div v-if="!isFetching && !assessments.length"
      class="grey-50">
      <el-button
        v-if="user"
        class="v-button v-button--primary v-button--large"
        type="primary"
        size="large"
        @click="handleStart"
      >
        Start new assessment
      </el-button>

      <p v-else>Error retrieving user data</p>
    </div>

    <div class="s-content">
      <div v-for="assessment in assessments"
        class="bg-grey-100 p-8 mb-12 md:mb-20"
        :key="assessment.id">
        Last updated: {{  format( new Date(assessment.updatedAt), DATE_FORMAT)  }}<br/>
        Status: {{ assessment.status }}<br/>

        <div class="mt-10">
          <el-button
            class="v-button v-button--primary v-button--large"
            type="primary"
            size="large"
            @click="handleResume(assessment.guid, assessment.status, assessment.currentSection)"
          >
          {{ assessment.status === 'INCOMPLETE' ? 'Resume' : 'View results' }}
        </el-button>
      </div>
      </div>
    </div>
  </div>
</template>
